import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Fragment } from "react"
import styled from "styled-components"

import Container from "./Container"
import Logo from "./Logo"
import { GRAY, DARK_GRAY } from "../constants/colors"
import Image from "./Image"

const ContactWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  background: ${DARK_GRAY};
  color: white;
  font-size: 14px;
  padding: 8px;

  a {
    color: inherit;
    text-decoration: none;
  }
`

const ContactColumn = styled.div`
  font-size: 16px;
`

const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;

    > * {
      display: none;
    }

    ${ContactColumn} {
      display: block;
    }
  }
`

const Wrapper = styled.header`
  padding: 8px;
  background: ${GRAY};
  box-shadow: 0px 1px 3px rgba(26, 26, 26, 0.1),
    0px 0px 2px rgba(26, 26, 26, 0.1);
`

const StyledLogo = styled(Logo)`
  display: block;
  width: 70px;
  height: 70px;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Logos = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
`

const MenuLink = styled.a`
  flex-basis: 13%;
  transform: scale(0.9);
  transition: transform ease-in 0.1s;
  background: #545454;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  position: relative;

  ${({ active }) => active && `transform: scale(1);`}

  :hover {
    transform: scale(1);
  }

  &::after {
    content: "";
    display: block;
    padding-top: 100%;
  }

  @media (max-width: 1130px) {
    flex-basis: 17%;
  }

  @media (max-width: 700px) {
    flex-basis: 30%;
    font-size: 18px;
  }
`

const BannersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const Header = ({ siteTitle }) => (
  <Fragment>
    <ContactWrapper>
      <Container>
        <ContactContainer>
          <div>ul. Sportowa 8, Dąbrowa Chełmińska</div>
          <ContactColumn>
            DOSTAWA <a href="tel:662000633">662 000 633</a>
          </ContactColumn>
          <div>pon - sob: 11.00 - 20.00, niedziela: 11.00 - 18.00</div>
        </ContactContainer>
      </Container>
    </ContactWrapper>
    <Wrapper>
      <Container>
        <FlexContainer>
          <Link to="/">
            <StyledLogo />
          </Link>
        </FlexContainer>
      </Container>
    </Wrapper>
    <Container>
      <Logos>
        <MenuLink
          as={Link}
          active={
            typeof window !== `undefined` &&
            window.location.pathname === "/menu"
          }
          to="/menu"
        >
          Menu
        </MenuLink>
        <MenuLink
          as={Link}
          active={
            typeof window !== `undefined` && window.location.pathname === "/"
          }
          to="/"
        >
          Gorące
          <br />
          półmiski
        </MenuLink>
        <MenuLink
          active={
            typeof window !== `undefined` &&
            window.location.pathname === "/catering"
          }
          target="_blank"
          href="/catering-2023.pdf"
        >
          Catering
        </MenuLink>
      </Logos>
      <div>
        <div style={{ maxWidth: "600px", margin: "0 auto" }}>
          <Image src="banner/1.jpg" />
        </div>
        <BannersContainer>
          <div>
            <Image src="banner/2.jpg" />
          </div>
          <div>
            <Image src="banner/3.jpg" />
          </div>
        </BannersContainer>
      </div>
    </Container>
  </Fragment>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
